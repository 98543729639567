.system-icon {
    @apply w-full h-full;

    path, line, rect, polyline, circle, ellipse, polygon {
        @apply stroke-current #{!important};
        @apply fill-current
    }
}

.sw-1 .heroicon-icon path {
    @apply stroke-1 #{!important};
}

.sw-1-5 .heroicon-icon path {
    stroke-width: 1.5px !important;
}

.healthicon-icon {
    @apply w-full h-full;

    path {
        @apply fill-current #{!important};
    }
}

input[type=file]::-webkit-file-upload-button,
input[type=file]::file-selector-button {
    @apply text-white bg-blue-800 hover:bg-blue-700 text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
}

.input-range::-webkit-slider-thumb {
    pointer-events: all;
    width: 24px;
    height: 24px;
    -webkit-appearance: none;
}

// Alpine js x-cloak directive style
[x-cloak] {
    display: none !important;
}