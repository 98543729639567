.system-icon {
  @apply w-full h-full;
}
.system-icon path, .system-icon line, .system-icon rect, .system-icon polyline, .system-icon circle, .system-icon ellipse, .system-icon polygon {
  @apply stroke-current !important;
  @apply fill-current;
}

.sw-1 .heroicon-icon path {
  @apply stroke-1 !important;
}

.sw-1-5 .heroicon-icon path {
  stroke-width: 1.5px !important;
}

.healthicon-icon {
  @apply w-full h-full;
}
.healthicon-icon path {
  @apply fill-current !important;
}

input[type=file]::-webkit-file-upload-button,
input[type=file]::file-selector-button {
  @apply text-white bg-blue-800 hover:bg-blue-700 text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
  margin-inline-start: -1rem;
  margin-inline-end: 1rem;
}

.input-range::-webkit-slider-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
}

[x-cloak] {
  display: none !important;
}

/* _.~"~._.~"~._.~"~._.~"~._. TRANSITIONS ._.~"~._.~"~._.~"~._.~"~._ */
.tippy-box {
  @apply transform transition transition-all;
}

.tippy-box[data-animation=translateY][data-state=hidden] {
  @apply opacity-0 -translate-y-2;
}

/* _.~"~._.~"~._.~"~._.~"~._. THEMES ._.~"~._.~"~._.~"~._.~"~._ */
.tippy-box[data-theme~=white] {
  @apply p-1 bg-white text-blue-800 shadow-md rounded-lg;
}
.tippy-box[data-theme~=white] .tippy-content {
  @apply p-0 rounded-lg;
}
.tippy-box[data-theme~=white] .tippy-arrow {
  @apply text-white;
}