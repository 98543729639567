/* _.~"~._.~"~._.~"~._.~"~._. TRANSITIONS ._.~"~._.~"~._.~"~._.~"~._ */

.tippy-box {
  @apply transform transition transition-all
}

.tippy-box[data-animation='translateY'][data-state='hidden'] {
  @apply opacity-0 -translate-y-2
}

/* _.~"~._.~"~._.~"~._.~"~._. THEMES ._.~"~._.~"~._.~"~._.~"~._ */

.tippy-box[data-theme~='white'] {
  @apply p-1 bg-white text-blue-800 shadow-md rounded-lg;
  .tippy-content {
    @apply p-0 rounded-lg
  }
  .tippy-arrow {
    @apply text-white
  }
}
